.wh-rtd-embeddedobject {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  clear: both;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.wh-rtd-embeddedobject--block {
  padding: 5px 0;
}

.wh-rtd-editor-bodynode--copying .wh-rtd-embeddedobject {
  -moz-user-select: all;
  user-select: all;
}

.wh-rtd-embeddedobject__box {
  position: relative;
  background: #f9f9f9;
  color: #111111;
  font-size: 12px;
  pointer-events: none;
}

div.wh-rtd-embeddedobject__box {
  border: 1px dashed #aaaaaa;
  border-radius: 3px;
  margin: 0;
  line-height: 100%;
  min-height: 38px;
}

span.wh-rtd-embeddedobject__box {
  white-space: nowrap;
}

.wh-rtd-embeddedobject--selected:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 3px;
  left: -5px;
  right: -5px;
  background-color: #ddd;
}

.wh-rtd-editor:focus-within .wh-rtd-embeddedobject--selected:before {
  background-color: highlight;
}

div.wh-rtd--margins-compact > .wh-rtd > .wh-rtd-embeddedobject--block {
  margin: 0 -8px;
}
div.wh-rtd--margins-compact > .wh-rtd > .wh-rtd-embeddedobject--block .wh-rtd-embeddedobject__preview {
  padding: 2px 8px;
}

div.wh-rtd--margins-wide > .wh-rtd > .wh-rtd-embeddedobject--block {
  margin: 0 -88px;
}
div.wh-rtd--margins-wide > .wh-rtd > .wh-rtd-embeddedobject--block:not(.wh-rtd-embeddedobject--wide) .wh-rtd-embeddedobject__preview {
  padding: 2px 88px;
}

.wh-rtd-embeddedobject__preview {
  position: relative;
  overflow-x: hidden;
  pointer-events: none;
  filter: grayscale(100%);
  transition: filter 300ms;
  z-index: 0;
}

.wh-rtd-embeddedobject--block .wh-rtd-embeddedobject__preview {
  padding: 2px 8px;
}

.wh-rtd-embeddedobject:hover .wh-rtd-embeddedobject__preview {
  filter: grayscale(0%);
}

.wh-rtd-objectbuttons {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #777;
  box-shadow: rgba(127, 127, 127, 0.1) 0 0 0 3px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  pointer-events: all;
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 5px;
  height: 26px;
}

.wh-rtd-navabovebutton,
.wh-rtd-navunderbutton,
.wh-rtd-editbutton,
.wh-rtd-deletebutton {
  width: 26px;
  display: inline-block;
  vertical-align: top;
  height: 24px;
  cursor: pointer;
}

.wh-rtd-editbutton,
.wh-rtd-deletebutton {
  border-left: 1px solid #b3b3b3;
}

.wh-rtd-embeddedobject:not(.wh-rtd-embeddedobject--editable) .wh-rtd-editbutton,
.wh-rtd-embeddedobject:not(.wh-rtd-embeddedobject--editable) .wh-rtd-editbutton + .wh-rtd-separator {
  display: none;
}

.wh-rtd-embeddedobject__stickyheader {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  transition: opacity 300ms;
  z-index: 1;
}

.wh-rtd-embeddedobject:hover > * > .wh-rtd-embeddedobject__stickyheader {
  opacity: 1;
}

.wh-rtd-embeddedobject--block > * > .wh-rtd-embeddedobject__stickyheader {
  position: absolute;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
}

.wh-rtd-embeddedobject--inline > * > .wh-rtd-embeddedobject__stickyheader {
  display: inline;
  left: 0;
  right: 0;
}

.wh-rtd-embeddedobject--inline .wh-rtd-embeddedobject__typebox {
  display: none;
}

.wh-rtd-embeddedobject__typebox {
  background-color: #2486db;
  border-bottom-right-radius: 5px 5px;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  font: bold 12px Roboto, Helvetica, Arial, sans-serif;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}

span.wh-rtd-embeddedobject__typebox {
  top: 100%;
}

.wh-rtd__inlinepreview {
  display: inline-block;
  border: 1px solid #9b9b9b;
  border-radius: 3px;
  background-color: #dddddd;
  padding-top: 2px;
}

.wh-rtd__inlinepreview__iconholder {
  display: inline;
  vertical-align: middle;
}

.wh-rtd__inlinepreview__iconholder > .wh-rtd__inlinepreview__icon {
  vertical-align: bottom;
  margin-bottom: 2px;
}

.wh-rtd__inlinepreview__title {
  display: inline-block;
  padding: 2px 3px 3px 0px;
  font-weight: bold;
}

.wh-rtd-embeddedobject.wh-rtd-embeddedobject--hasinlinepreview {
  background: transparent;
}