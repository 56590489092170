// Auto-generated RPC interface from /opt/wh/whtree/modules/publisher/js/forms/internal/form.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("publisher:forms");
exports.rpcResolve = function (promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function () { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function () { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function () { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function () { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function () { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function () { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function () { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function () { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/wh/whtree/modules/publisher/lib/internal/forms/rpc.whlib'

exports.formSubmit = exports.FormSubmit = /*RECORD*/function (/*RECORD*/ submitinfo)
{
return request.invoke.apply(request,["FormSubmit"].concat(Array.prototype.slice.call(arguments)));
}

exports.formInvoke = exports.FormInvoke = /*RECORD*/function (/*RECORD*/ submitinfo)
{
return request.invoke.apply(request,["FormInvoke"].concat(Array.prototype.slice.call(arguments)));
}

exports.getUploadedFileFinalURL = exports.GetUploadedFileFinalURL = /*STRING*/function (/*STRING*/ uploadurl)
{
return request.invoke.apply(request,["GetUploadedFileFinalURL"].concat(Array.prototype.slice.call(arguments)));
}

exports.getImgFromRemoteURL = exports.GetImgFromRemoteURL = /*STRING*/function (/*STRING*/ uploadurl)
{
return request.invoke.apply(request,["GetImgFromRemoteURL"].concat(Array.prototype.slice.call(arguments)));
}

exports.requestBuiltinForm = exports.RequestBuiltinForm = /*RECORD*/function (/*RECORD*/ submitinfo, /*STRING*/ filename, /*STRING*/ formname)
{
return request.invoke.apply(request,["RequestBuiltinForm"].concat(Array.prototype.slice.call(arguments)));
}

exports.validateEmbeddedObjects = exports.ValidateEmbeddedObjects = /*RECORD*/function (/*STRING ARRAY*/ objrefs)
{
return request.invoke.apply(request,["ValidateEmbeddedObjects"].concat(Array.prototype.slice.call(arguments)));
}

exports.validateEmail = exports.ValidateEmail = /*RECORD*/function (/*STRING*/ langcode, /*STRING*/ emailaddress)
{
return request.invoke.apply(request,["ValidateEmail"].concat(Array.prototype.slice.call(arguments)));
}

exports.verifyAddress = exports.VerifyAddress = /*RECORD*/function (/*STRING*/ url, /*RECORD*/ value, /*RECORD*/ options)
{
return request.invoke.apply(request,["VerifyAddress"].concat(Array.prototype.slice.call(arguments)));
}
