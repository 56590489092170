.wh-holder
{
  box-sizing:border-box;
}
.wh-toolbar
{
}
.wh-toolbar > *
{
  display:none;
}
.wh-toolbar > *.open
{
  display: block;
}
.wh-toolbar-panel > *,
.wh-toolbar-modalholder > *
{
  display: inline-block;
}
