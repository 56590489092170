import "./form.scss";
import "../../shared/site.lang.json";

import * as dompack from "dompack";
import AutoSuggest from 'dompack/components/autosuggest';
import { config } from "@mod-system/js/wh/integration";
import * as forms from "@mod-publisher/js/forms";
import { getTid } from "@mod-tollium/js/gettid";

let maxloops = 1000;

window.dataLayer = window.dataLayer || [];

class EcheckForm extends forms.RPCFormBase
{
  constructor(node)
  {
    super(node);

    this.history = [];

    this.registerHandlers();
    this.selectPage(config.obj.firstpage);
  }

  registerHandlers()
  {
    if (this.elements.bachelormaster)
      for (let radio of this.elements.bachelormaster)
        radio.addEventListener("change", e => this.nextPageLocked(e));

    if (this.elements.programme)
    {
      if (this.elements.programme.length)
        for (let radio of this.elements.programme)
          radio.addEventListener("change", e => this.nextPageLocked(e));
      else
        this.elements.programme.addEventListener("change", e => this.nextPageLocked(e));
    }

    if (this.elements.university_city && this.elements.university_name)
    {
      this.elements.university_city.autocomplete = "off";
      this._uniCitySuggester = new AutoSuggest(this.elements.university_city, _ => this.lookupUniCity(_), { minlength: 1 });
      //this.elements.university_city.addEventListener("dompack:autosuggest-selected", () => this.onUniCitySelected() );

      this.elements.university_name.autocomplete = "off";
      this._uniNameSuggester = new AutoSuggest(this.elements.university_name, _ => this.lookupUniName(_), { minlength: 1 });
      //this.elements.university_name.addEventListener("dompack:autosuggest-selected", () => this.onUniNameSelected() );

      //ADDME: It would be nice if there were some public API to reset the lookup cache, or to set some sort of context for
      //       the lookup cache
      // Reset the city and name autosuggests when the country changes
      this.elements.country.addEventListener("change", () =>
      {
        this._uniCitySuggester._lookuphistory = [];
        this._uniNameSuggester._lookuphistory = [];
      });
      // Reset the name autosuggest when the city changes
      this.elements.university_city.addEventListener("change", () =>
      {
        this._uniNameSuggester._lookuphistory = [];
      });
    }

    // FIXME: catching right-clicks too
    dompack.qSA(this.node, ".echeck__prevbutton").forEach(node => node.addEventListener("click", e => this.prevPage(e)));
    dompack.qSA(this.node, ".echeck__nextbutton").forEach(node => node.addEventListener("click", e => this.nextPageLocked(e)));

    window.addEventListener("popstate", e => this.onPageStateChange(e.state));
  }

  async lookupUniCity(q)
  {
    let request = await fetch("/.radboud/suggest.shtml?t=c&q=" + encodeURIComponent(q) + "&l=" + encodeURIComponent(this.elements.country.value));
    let response = await request.json();
    return response.results;
  }

  async lookupUniName(q)
  {
    let request = await fetch("/.radboud/suggest.shtml?t=n&q=" + encodeURIComponent(q) + "&c=" + encodeURIComponent(this.elements.university_city.value));
    let response = await request.json();
    return response.results;
  }

  onPageStateChange(state)
  {
    // If the state is null, we're back on the front page, otherwise the state should contain a 'bama' value
    if (!state)
    {
      // Navigating back from the programme selection page to the bachelor/master choice page, or refreshing the programme
      // selection page
      if (!this.history.includes("bachelormaster"))
        this.history.unshift("bachelormaster"); // Push to the front of the history, so we can go to prev page
      this.prevPage();
    }
    else if (state.bama)
    {
      // The history contains the config.obj.firstpage, if that's (only) 'programme', the user navigated back from a
      // programme-specific form page to the programme selection page
      if (!this.history.includes("bachelormaster"))
      {
        this.history.unshift("bachelormaster"); // Push to the front of the history
      }
      else if (!this.history.includes("programme"))
      {
        // The user navigated forward from the bachelor/master choice page
        this.nextPage();
      }
      else
      {
        // The user navigated back again to the bachelor/master choice page
        this.prevPage();
      }
    }
  }

  selectPage(pageid)
  {
    let prev = this.node.querySelector(".echeck__page--selected");
    prev && prev.classList.remove("echeck__page--selected");

    let pos = this.history.indexOf(pageid);
    if (pos === -1)
    {
      if (dompack.debugflags.ruecheckdebug)
        console.log("adding to history: ", pageid);
      this.history.push(pageid);
    }
    else
    {
      if (dompack.debugflags.ruecheckdebug)
        console.log("history popping ", this.history.length - pos  - 1, "items");
      this.history = this.history.slice(0, pos + 1);
    }

    let current = this.getPageById(pageid);
    current.classList.add("echeck__page--selected");

    this.sendFormEvent('radboud_echeck:selectpage', { ds_pageid: pageid });

    dompack.qS(".echeck__prevbutton").disabled = this.history.length <= 1;
    dompack.qS(".echeck__nextbutton").disabled = current.dataset.disablenextaction;

    if (pageid == "programme")
    {
      // Deselect all so an change event will be triggered (again) upon selecting (click/kb nav) an item
      if ("programme" in this.elements)
        for (let prog of Array.from(this.elements.programme))
          prog.checked = false;

      // This means "Bachelor" or "Master" was selected, so update the section label
      document.documentElement.classList.add("page--echeck--typeselected");
      if (this.getSelectedBachelorMaster() == "bachelor")
        document.querySelector(".sectionlabelblock").innerText = "Bachelor";
      else
        document.querySelector(".sectionlabelblock").innerText = "Master";

      if (prev)
      {
        if (this.getSelectedBachelorMaster() && history.pushState)
          history.pushState({ bama: this.getSelectedBachelorMaster() }, document.title, this.getSelectedBachelorMaster());
      }

      /*
      // Update the label showing the amount of available eligibilty checks
      let num = dompack.qSA('.wh-form__fieldgroup--radiogroup[data-wh-form-group-for="programme"] .wh-form__fieldline:not(.wh-form__fieldline--hidden)').length;
      dompack.qS(".echeck__page--programme .wh-form__label").textContent = getTid("radboud:site.js.echeck.programme", num || "");
      */
    }
  }

  getPageById(pageid)
  {
    let page = this.node.querySelector(".echeck__page[data-pageid=\"" + pageid + "\"]");
    if (!page)
      throw new Error("No such page '" + pageid + "'");
    return page;
  }

  getPageNextAction(page, ignoreanswers)
  {
    let nextaction = page.dataset.nextaction;
    let selectedoption = dompack.qS(page, "input[type=radio]:checked,option:checked");

    if (!ignoreanswers && selectedoption && selectedoption.dataset.nextaction)
      nextaction = selectedoption.dataset.nextaction;

    if (!nextaction)
      throw new Error("Page " + page.dataset.pageid + " does not have a nextaction!");

    nextaction = JSON.parse(nextaction);
    return nextaction;
  }

  getCurrentSkipGroups()
  {
    let retval = [];
    this.history.forEach(pageid =>
    {
      let page = this.getPageById(pageid);
      let nextaction = this.getPageNextAction(page);
      if (nextaction.skipgroups)
        retval.push(...nextaction.skipgroups);
    });
    return retval;
  }

  prevPage()
  {
    if (this.history.length <= 1)
      return;
    this.selectPage(this.history[this.history.length - 2]);
  }

  async nextPageLocked()
  {
    let nextpagelock = dompack.flagUIBusy();
    try
    {
      return await this.nextPage();
    }
    catch (e)
    {
      console.error(e);
    }
    finally
    {
      nextpagelock.release();
    }
  }

  getSelectedBachelorMaster()
  {
    if (this.elements.bachelormaster)
      for (let bama of this.elements.bachelormaster)
        if (bama.checked)
          return bama.value;
    return "";
  }

  getSelectedProgrammeId()
  {
    if (this.elements.programme)
    {
      for (let prog of this.elements.programme)
        if (prog.checked)
          return parseInt(prog.value.split("|")[0]) || 0;
      return 0;
    }
    return config.obj.programme_id;
  }

  getSelectedProgrammeName()
  {
    if (this.elements.programme)
      for (let prog of this.elements.programme)
        if (prog.checked)
          return prog.value.split("|")[1];
    return "";
  }

  async nextPage()
  {
    if (document.documentElement.classList.contains("echeck--pagebusy"))
      return;
    document.documentElement.classList.add("echeck--pagebusy");
    let lock = dompack.flagUIBusy({ ismodal: true, component: this.node });

    try
    {
      let current = this.node.querySelector(".echeck__page--selected");
      if(!(await this.validate(current)).valid)
      {
        if (dompack.debugflags.ruecheckdebug)
          console.log("Validation failed");
        return;
      }
      //FIXME
      // if (!$(this.node).parsley().validate( { group: current.dataset.pageid }))
      // {
      //   console.log("Validation failed");
      //   return;
      // }

      let counter = 0;
      let nextaction = this.getPageNextAction(current);
      // console.log(nextaction);

      let baseforminfo = { guid:         config.obj.formentry_guid
                         , history:      this.history
                         , programmeid:  this.getSelectedProgrammeId()
                         };

      while (true)
      {
        if (++counter === maxloops)
          throw new Error("Infinite loop in form pages path");
        if (!nextaction)
          throw new Error("Looped without setting next action!");

        let action = nextaction;
        nextaction = null;

        this.sendFormEvent('radboud_echeck:nextpage', { ds_nextaction: action.type });
        switch (action.type)
        {
          case "sendresumptionlink":
          {
            await this.invokeRPC("SendEmailWithLink", { programmeid: baseforminfo.programmeid });

            // FIXME: RPC that sends an e-mail with a resumption
            this.selectPage(action.pageid);
            return;
          }
          case "done":
          {
            // Release the lock, because the form submission will also try to take a lock
            lock.release();
            lock = null;

            let submitdata =
                { ...baseforminfo
                , finished:     true
                , resultcode:    action.resultcode
                };

            let submitresult = await this.submit(submitdata);
            window.dataLayer.push(
              { event: 'formulier-submit'
              , form: action.resultcode.toUpperCase() != 'EL' ? 'eligibilitycheck-failed'
                                                              : submitresult.result.notyetquestions.length ? 'eligibilitycheck-notyet'
                                                              : 'eligibilitycheck-positive'
              , form_programme: submitdata.programmeid
              , form_resultcode: action.resultcode
              , echeck_notyetquestions: submitresult.result.notyetquestions
              });

            dompack.empty(dompack.qS("#echeck__maincontents"));
            dompack.qS("#echeck__maincontents").innerHTML = submitresult.result.contents;
            return;
          }
          case "nextpage-nostore":
          case "nextpage":
          case "nextpage-modalrpc":
          {
            if (action.informecheckstart)
            {
              window.dataLayer.push(
                { event: 'formulier-submit'
                , form: 'eligibilitycheck-enter'
                , form_programme: baseforminfo.programmeid
                , form_question: action.question
                });
            }
            else if (action.question)
            {
              window.dataLayer.push(
                { event: 'formulier-submit'
                , form: 'eligibilitycheck-nextquestion'
                , form_programme: baseforminfo.programmeid
                , form_question: action.question
                });
            }
            if (action.type !== "nextpage-nostore" && counter === 1) // only save in the first loop iteration
            {
              let data =
                  { ...baseforminfo
                  , finished: false
                  };

              if (action.type === "nextpage-modalrpc")
              {
                let res = await this.invokeRPC("storeData", data);
                config.obj.formentry_guid = res.guid;
              }
              else
                this.invokeBackgroundRPC("storeData", data).then(res => config.obj.formentry_guid = res.guid);
            }

            //console.log("Selecting next page: ", action.pageid);

            let skipgroups = this.getCurrentSkipGroups();
            let nextpage = this.getPageById(action.pageid);

            let groupid = parseInt(nextpage.dataset.groupid);
            if (!skipgroups.includes(groupid))
            {
              this.selectPage(action.pageid);
              return;
            }

            nextaction = this.getPageNextAction(nextpage, true);
            if (counter >= maxloops - 20 && dompack.debugflags.ruecheckdebug)
              console.log("skipping to page " + nextaction.pageid);
            //console.log("Skip page, next action", nextaction);
          } break;
          case "init-programme":
          {
            // Reload the form for the chosen programme
            let gotourl = new URL(location.href);
            gotourl.pathname += (gotourl.pathname.endsWith("/") ? "" : "/") + this.getSelectedProgrammeName();
            //gotourl.searchParams.set('progid', this.elements.programme.value);
            location.href = gotourl.toString();
            return;
          }
          case "noecheck":
          {
            let gotourl = new URL(location.href);
            gotourl.searchParams.set('nocheck', action.diploma);
            location.href = gotourl.toString();
            return;
          }
          default:
          {
            throw new Error("unknown action " + action.type);
          }
        }
      }
    }
    finally
    {
      if (lock)
        lock.release();
      document.documentElement.classList.remove("echeck--pagebusy");
    }
  }
}

dompack.onDomReady(() => forms.registerHandler("radboud:echeck", node => new EcheckForm(node)));
